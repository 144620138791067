import React, { useEffect, useState } from 'react';

import { Box } from '@core';

import { FAQSection } from '@containers';
import { getFAQ } from '@api/zendesk';

const remapQuestions = (questions) =>
  questions?.map((question) => ({
    header: question.name,
    content: <div dangerouslySetInnerHTML={{ __html: question.body }} />,
  }));

const remapCustomQuestions = (questions) =>
  questions?.map((question) => ({
    header: question.title,
    content: question.description,
  }));

const FAQ = ({ fetchedQuestions, customQuestions, padding, ...rest }) => {
  const [fetchedItems, setFetchedItems] = useState([]);

  const [addedCustomQuestions, setAddedCustomQuestions] = useState(
    customQuestions ? remapCustomQuestions(customQuestions) : []
  );
  const [addedZendeskQuestions, setAddedZendeskQuestions] = useState([]);

  const [questions, setQuestions] = useState(addedCustomQuestions);

  useEffect(() => {
    getFAQ().then((fetchedItems) => {
      setFetchedItems(fetchedItems);
    });
  }, []);

  useEffect(() => {
    const selectedQuestions = fetchedItems.filter((fetchedItem) =>
      fetchedQuestions?.find((item) => +item === fetchedItem.id)
    );
    setAddedZendeskQuestions(remapQuestions(selectedQuestions));
  }, [fetchedItems, fetchedQuestions]);

  useEffect(() => {
    customQuestions && setAddedCustomQuestions(remapCustomQuestions(customQuestions));
  }, [customQuestions]);

  useEffect(() => {
    setQuestions([...addedCustomQuestions, ...addedZendeskQuestions]);
  }, [addedCustomQuestions, addedZendeskQuestions]);

  return (
    <Box {...padding}>
      <FAQSection {...rest} items={questions} padding={{ py: 40 }} />
    </Box>
  );
};

export default FAQ;
